(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("jQuery"));
	else if(typeof define === 'function' && define.amd)
		define("atk", ["jQuery"], factory);
	else if(typeof exports === 'object')
		exports["atk"] = factory(require("jQuery"));
	else
		root["atk"] = factory(root["jQuery"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__21145__) => {
return 